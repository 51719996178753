import { Button, Typography } from '@material-tailwind/react';
import { useDetailUserManagement } from 'api/user-management';
import Modal from "components/Modal";
import ModalBody from "components/ModalBody";
import ModalFooter from "components/ModalFooter";
import ModalHeader from "components/ModalHeader";
import { USER_ROLE } from 'constants/global-variable';
import { MENU_ACCESS_DATA } from 'constants/mapping-access-data';
import { OPTIONS_GENDER_USER, OPTIONS_POLYCLINIC_UNIT, OPTIONS_USER_TYPE } from 'constants/options';
import { Label, Select, Table, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TbCheck, TbX } from 'react-icons/tb';
import { UserManagementUpdateType } from 'types/user-management-update';

interface UpdateUserProps {
    isOpen: boolean;
    onClose: () => void;
    onUpdate: (user: UserManagementUpdateType) => void;
    user_id: string;
}

const ModalUpdateUser = ({ isOpen, onClose, user_id, onUpdate }: UpdateUserProps) => {

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<UserManagementUpdateType>();

    const [selectedRole, setSelectedRole] = useState('');

    const handleUpdateUser = (val: UserManagementUpdateType) => {
        const updatedUser = { ...val };
        if (Number(selectedRole) === USER_ROLE.PHARMACY) {
            updatedUser.poly_clinic = null;
        }
        onUpdate(updatedUser);
        onClose();
    }

    const { data } = useDetailUserManagement({ user_id });

    useEffect(() => {
        if (user_id) {
            setValue("email", data?.data?.data?.user?.email);
            setValue("full_name", data?.data?.data?.user?.full_name);
            setValue("phone_number", data?.data?.data?.user?.phone_number);
            setValue("gender", data?.data?.data?.user?.gender);
            setValue("birth_date", data?.data?.data?.user?.birth_date);
            setValue("poly_clinic", data?.data?.data?.user?.poly_clinic);
            setValue("type", data?.data?.data?.user?.type);
            setSelectedRole(data?.data?.data?.user?.type);
        }
    }, [setValue, data, user_id]);

    const getMenuAccess = (role: string) => {
        const access = MENU_ACCESS_DATA.find((item) => item.role === role);
        return access ? access.menus : [];
    };

    return (
        <Modal open={isOpen} onClose={onClose}>
            <ModalHeader>Ubah User</ModalHeader>
            <ModalBody>
                <form id="userUpdateForm" noValidate onSubmit={handleSubmit(handleUpdateUser)}>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-4 col-span-1 md:col-span-2">
                            <Label htmlFor="email" value="Email" />
                            <TextInput
                                id="email"
                                type="email"
                                defaultValue=""
                                required
                                color={Boolean(errors?.email) ? "failure" : "gray"}
                                helperText={errors?.email?.message}
                                {...register('email', {
                                    validate: (value) => {
                                        if (!value) {
                                            return "Email tidak boleh kosong";
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                                            return "Email tidak valid";
                                        }
                                        return true;
                                    }
                                })}
                                disabled={Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? true : false}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="full_name" value="Nama Lengkap" />
                            <TextInput
                                id="full_name"
                                type="text"
                                defaultValue=""
                                required
                                color={Boolean(errors?.full_name) ? "failure" : "gray"}
                                helperText={errors?.full_name?.message}
                                {...register('full_name', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                                disabled={Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? true : false}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="phone_number" value="Nomor Telepon" />
                            <TextInput
                                id="phone_number"
                                type="number"
                                defaultValue=""
                                required
                                color={Boolean(errors?.phone_number) ? "failure" : "gray"}
                                helperText={errors?.phone_number?.message}
                                {...register('phone_number', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                                disabled={Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? true : false}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="birth_date" value="Tanggal Lahir" />
                            <TextInput
                                id="birth_date"
                                type="date"
                                defaultValue=""
                                required
                                color={Boolean(errors?.birth_date) ? "failure" : "gray"}
                                helperText={errors?.birth_date?.message}
                                {...register('birth_date', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                                disabled={Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? true : false}
                            />
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="gender" value="Jenis Kelamin" />
                            <Select
                                id="gender"
                                defaultValue=""
                                required
                                color={Boolean(errors?.gender) ? "failure" : "gray"}
                                helperText={errors?.gender?.message}
                                {...register('gender', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                })}
                                disabled={Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? true : false}
                            >
                                <option value="">
                                    Pilih jenis kelamin
                                </option>
                                {OPTIONS_GENDER_USER.map((item) => (
                                    <option key={item.key} value={item.key}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        <div className="mb-4">
                            <Label htmlFor="type" value="Role" />
                            <Select
                                id="type"
                                defaultValue=""
                                required
                                color={Boolean(errors?.type) ? "failure" : "gray"}
                                helperText={errors?.type?.message}
                                {...register('type', {
                                    required: {
                                        value: true,
                                        message: 'Wajib diisi',
                                    },
                                    onChange: (e) => {
                                        setSelectedRole(e.target.value);
                                    }
                                })}
                            >
                                <option value="">
                                    Pilih role user
                                </option>
                                {OPTIONS_USER_TYPE.map((item) => (
                                    <option key={item.key} value={item.key}>
                                        {item.label}
                                    </option>
                                ))}
                            </Select>
                        </div>
                        {Number(selectedRole) !== USER_ROLE.PHARMACY && (
                            <div className="mb-4">
                                <Label htmlFor="poly_clinic" value="Poli Klinik" />
                                <Select
                                    id="poly_clinic"
                                    defaultValue=""
                                    required
                                    color={Boolean(errors?.poly_clinic) ? "failure" : "gray"}
                                    helperText={errors?.poly_clinic?.message}
                                    {...register('poly_clinic', {
                                        required: {
                                            value: true,
                                            message: 'Wajib diisi',
                                        },
                                    })}
                                >
                                    <option value="">
                                        Pilih poli/unit
                                    </option>
                                    {OPTIONS_POLYCLINIC_UNIT.map((item) => (
                                        <option key={item.key} value={item.key}>
                                            {item.label}
                                        </option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </div>
                    {selectedRole && (
                        <div className="py-2">
                            <Typography variant="h6" color="blue-gray">
                                Akses Menu untuk {OPTIONS_USER_TYPE.find((u) => u.key === Number(selectedRole)).label} {Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? '(Admin)' : ''}:
                            </Typography>
                            <Table>
                                <Table.Head>
                                    <Table.HeadCell>Menu</Table.HeadCell>
                                    <Table.HeadCell>{OPTIONS_USER_TYPE.find((u) => u.key === Number(selectedRole)).label} {Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? '(Admin)' : ''}</Table.HeadCell>
                                </Table.Head>
                                <Table.Body>
                                    {getMenuAccess(selectedRole).map((menu, index) => (
                                        <Table.Row key={index}>
                                            <Table.Cell>{menu.name}</Table.Cell>
                                            <Table.Cell className="text-center">{Number(data?.data?.data?.user?.is_admin) === USER_ROLE.ADMIN ? <TbCheck color='green' /> : menu.access ? <TbCheck color='green' /> : <TbX color='red' />}</Table.Cell>
                                        </Table.Row>
                                    ))}
                                </Table.Body>
                            </Table>
                        </div>
                    )}
                </form>
            </ModalBody>
            <ModalFooter>
                <Button form="userUpdateForm" type="submit">Ubah</Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalUpdateUser;
