import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  IconButton,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import { MAIN_MENU, EXTRAS_MENU } from "constants/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { TbChevronRight, TbX } from "react-icons/tb";
import useAppContext from "context/AppContext";

function Sidebar({ isSidebarOpen, toggleSidebar }) {
  const { isMobile, clinicDetail } = useAppContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState("");

  const menus = [MAIN_MENU, EXTRAS_MENU];

  const isInWhitelist = process.env.REACT_APP_PHARMACY_WHITELIST.split(",").includes(clinicDetail?.clinic_id.toString());

  const handleOpen = (value) => {
    setOpen(open === value ? "" : value);

    if (!isSidebarOpen && open !== value) {
      toggleSidebar();
    }
  };

  const handleRedirect = (url: string) => {
    if (url === pathname) {
      window.location.reload();
    } else {
      navigate(url);
    }
  }

  useEffect(() => {
    if (isSidebarOpen && isMobile) {
      toggleSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <aside
      className={`fixed inset-y-0 z-10 flex max-h-screen w-64 flex-shrink-0 transform flex-col overflow-y-auto border-r bg-white px-1 shadow-lg transition-all lg:static lg:z-auto lg:shadow-none ${!isSidebarOpen ? "-translate-x-full lg:w-20 lg:translate-x-0" : ""
        }`}
    >
      <div
        className={`flex flex-shrink-0 items-center justify-between p-2 ${!isSidebarOpen ? "lg:justify-center" : ""
          }`}
      >
        <span className="mb-4 whitespace-nowrap p-2 text-xl font-semibold uppercase leading-8 tracking-wider">
          <img
            src={isSidebarOpen ? "/sidebar_logo.png" : "/sidebar_logogram.png"}
            alt="Mitramedis"
          />
        </span>
        <button onClick={toggleSidebar} className="rounded-md p-2 lg:hidden">
          <TbX size={24} className="text-gray-600" />
        </button>
      </div>
      <div className="grow">
        {menus.map((menu, menuIndex) => (
          <div key={`menu-${menuIndex}`} className="py-2">
            {isSidebarOpen && (
              <Typography
                variant="small"
                className="mb-2 pl-4 text-xs font-bold tracking-widest text-gray-900"
              >
                {menu.title}
              </Typography>
            )}
            <List>
              {menu.items.map((item, itemIndex) => {
                const hasChild = item?.child?.length;

                if (item.text === 'Farmasi' && !isInWhitelist) {
                  return null;
                }

                if (item.text === 'Kasir' && !isInWhitelist) {
                  return null;
                }

                return hasChild ? (
                  <React.Fragment key={`item-$${itemIndex}`}>
                    <ListItem
                      // selected={item.child.map((c) => c.url).includes(pathname)}
                      onClick={() => handleOpen(item.text)}
                      className={`p-2
                        ${isSidebarOpen ? "justify-start" : "justify-center"}`}
                    >
                      <ListItemPrefix>
                        <item.icon
                          strokeWidth={1.5}
                          size={isSidebarOpen ? 20 : 24}
                        />
                      </ListItemPrefix>
                      {isSidebarOpen && item.text}
                      {isSidebarOpen && (
                        <ListItemSuffix>
                          <HiChevronDown
                            strokeWidth={1.1}
                            className={`mx-auto h-4 w-4 transition-transform ${open === item.text ? "rotate-180" : ""
                              }`}
                          />
                        </ListItemSuffix>
                      )}
                    </ListItem>
                    {open === item.text && (
                      <List>
                        {item.child.map((c, cIndex) => (
                          <div
                            key={`child-${cIndex}`}
                            className="text-initial"
                            onClick={() => handleRedirect(c.url)}
                          >
                            <ListItem
                              selected={pathname === c.url}
                              className={`p-2 pl-8 ${isSidebarOpen
                                ? "justify-start"
                                : "justify-center"
                                }`}
                            >
                              {isSidebarOpen && c.text}
                            </ListItem>
                          </div>
                        ))}
                      </List>
                    )}
                  </React.Fragment>
                ) : (
                  <div
                    key={`item-$${itemIndex}`}
                    className="text-initial"
                    onClick={() => handleRedirect(item.url)}
                  >
                    <ListItem
                      key={`item-${itemIndex}`}
                      selected={pathname === item.url}
                      className={`break-keep p-2 ${isSidebarOpen ? "justify-start" : "justify-center"
                        }`}
                      onClick={() => handleOpen("")}
                    >
                      <ListItemPrefix>
                        <item.icon
                          strokeWidth={1.5}
                          size={isSidebarOpen ? 20 : 24}
                        />
                      </ListItemPrefix>
                      {isSidebarOpen && item.text}
                    </ListItem>
                  </div>
                );
              })}
            </List>
            {menuIndex === 0 && <hr className="my-2 border-gray-50" />}
          </div>
        ))}
      </div>
      <div className="h-14 flex-shrink-0 border-t p-2 flex items-center justify-center text-gray-600 cursor-pointer sticky bottom-0 bg-white">
        {/* <Typography variant="small" className="text-center">
          Mitramedis &copy; {new Date().getFullYear()}
        </Typography> */}
        <IconButton variant="outlined" className="border-none" onClick={toggleSidebar}>
          <TbChevronRight
            className={`${isSidebarOpen
              ? "-rotate-180 transform transition-transform"
              : ""
              } text-2xl`}
          />
        </IconButton>
      </div>
    </aside>
  );
}

export default Sidebar;
